import React, { FC, ReactElement } from 'react';
import { useUtils } from 'utils/useUtils';

interface ITopArticlesCard {
  index: number;
  title: string;
  tag: string;
  date: string;
  url: string;
}

const TopArticlesCard: FC<ITopArticlesCard> = ({ index, title, tag, date, url }: ITopArticlesCard): ReactElement => {
  const { convertDate } = useUtils();
  const formattedDate = convertDate(date);

  return (
    <li className="latest-news__card">
      <a className="latest-news__card-link" href={url}>
        <div className={`latest-news__card-indicator index-0${index}`} />
        <svg
          className="latest-news__card-icon"
          xmlns="http://www.w3.org/2000/svg"
          width="7"
          height="11"
          viewBox="0 0 7 11"
          fill="#000c37"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7 5.67432L2.12712 10.5472C1.6846 10.9897 0.967132 10.9897 0.524611 10.5472C0.0820899 10.1047 0.0820896 9.3872 0.524611 8.94468L3.79498 5.67432L0.524611 2.40395C0.0820899 1.96143 0.0820902 1.24396 0.524611 0.801438C0.967133 0.358917 1.6846 0.358917 2.12712 0.801438L7 5.67432Z"
            fill="#currentColor"
          />
        </svg>
        <h3 className="latest-news__card-title">{title}</h3>
        <div className="latest-news__card-details">
          <span className="latest-news__card-category">{tag}</span>
          <span className="latest-news__card-center-dot">&#183;</span>
          <span className="latest-news__card-date">{formattedDate}</span>
        </div>
      </a>
    </li>
  );
};

export default TopArticlesCard;
