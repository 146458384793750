import { ICategory, IQuestion, ISearchOptions } from 'sections/Company/HelpCenterPage/types';

export const useUtils = () => {
  const getDefaultActiveCategoryItems = (categoryId: number | null, array: ICategory[]) => {
    const itemsArray: any = [];
    array?.forEach((item: ICategory) => {
      if (item.id === categoryId) {
        itemsArray.push(...item.help_items);
      }
    });
    return itemsArray;
  };

  const generateQuestionLink = (questionId: number, subCatId: number) => {
    const pathname = location.pathname;
    const processedPathname = pathname.split('').slice(0, pathname.length).join('');
    return `${location.hostname}${processedPathname}#${subCatId}-${questionId}`;
  };

  const getQuestionIdFromUrl = (url: string = location.href) => {
    return url.split('#')[1]?.split('-').map(Number);
  };

  const checkAndPushQuestion = (categoriesArray: ICategory[], id: number | null, targetArray: IQuestion[]) => {
    categoriesArray?.forEach((item: ICategory) => {
      if (item.id === id && item.help_items !== null) {
        targetArray.push(...item.help_items);
      }
      if (item.children?.length) {
        checkAndPushQuestion(item.children, id, targetArray);
      }
    });
  };

  const renderQuestions = (categories: ICategory[], id: number | null) => {
    const array: IQuestion[] = [];
    checkAndPushQuestion(categories, id, array);
    return array;
  };

  const renderFilteredQuestions = (categories: ICategory[], searchOptions: ISearchOptions, searchValue: string) => {
    if (!categories) return [];
    if (searchValue === '') return [];

    const itemsSet: any = new Set();

    const findAndPush = (helps: IQuestion[], options: ISearchOptions, targetSet: any) => {
      helps.forEach((childQuestion: IQuestion) => {
        if (options.title) {
          if (childQuestion.name.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1) {
            targetSet.add(childQuestion);
          }
        }
        if (options.contents) {
          if (childQuestion.description.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1) {
            targetSet.add(childQuestion);
          }
        }
      });
    };

    const checkAndPushQuestion = (item: ICategory, options: ISearchOptions, targetSet: any) => {
      if (item.children) {
        item.children.forEach((child: ICategory) => {
          findAndPush(child.help_items, options, targetSet);
        });
      } else {
        findAndPush(item.help_items, options, targetSet);
      }
    };

    categories.forEach((item: ICategory) => {
      if (searchOptions.title && searchOptions.contents) {
        checkAndPushQuestion(item, { title: true, contents: true }, itemsSet);
      }
      if (searchOptions.title) {
        checkAndPushQuestion(item, { title: true, contents: false }, itemsSet);
      }
      if (searchOptions.contents) {
        checkAndPushQuestion(item, { title: false, contents: true }, itemsSet);
      }
      if (!searchOptions.title && !searchOptions.contents) {
        checkAndPushQuestion(item, { title: true, contents: true }, itemsSet);
      }
    });
    return [...itemsSet];
  };

  const setRequestUrlHostname = () => {
    const hostname = location.hostname.split('.')[2];
    let host = '';
    if (hostname === 'loc') {
      host = 'loc';
    } else if (hostname === 'dev') {
      host = 'dev';
    } else if (hostname === 'com') {
      host = 'com';
    }
    return host;
  };

  const defineAppDataType = (dataType: string | undefined) => {
    switch (dataType) {
      case 'shipper':
        return 'shipper';
      case 'carrier':
        return 'carrier';
      default:
        return 'shipper';
    }
  };

  const getCorrectPathname = (toRelease = false) => {
    const currentHostname = location.hostname;
    if (currentHostname.includes('df-alliance.loc')) {
      return 'https://www.searates.loc';
    } else if (currentHostname.includes('dev.df-alliance')) {
      if (toRelease) {
        return 'https://release.searates.dev';
      } else {
        return 'https://develop.searates.dev';
      }
    } else if (currentHostname.includes('df-alliance.com')) {
      return 'https://www.searates.com';
    } else {
      return 'https://www.searates.com';
    }
  };

  const convertDate = (sourceDate: string) => {
    const toUTCString = new Date(sourceDate).toUTCString().split(' ');
    const [, day, month, year] = toUTCString;
    return `${day} ${month}, ${year}`;
  };

  const capitalizeFirstChar = (source: string): string => {
    return source.charAt(0).toLocaleUpperCase() + source.slice(1);
  };

  const parseCountries = (url: string): string => {
    const splitUrl = url.split('-');
    const result = splitUrl.map((item: string) => {
      if (item === 'and' || item === 'the' || item === 'of') return item;
      return item.charAt(0).toLocaleUpperCase() + item.slice(1)
    });
    return result.join(' ');
  };

  const BlacklistUtils: any = {
    getAutofillData: (sourceArray: [], key: string, parameter: string = '') => {
      const resultArray = new Set();

      sourceArray.forEach((userObject) => {
        if (parameter === 'company') {
          // @ts-ignore
          resultArray.add(userObject.company[key]);
        } else if (parameter === 'personal') {
          // @ts-ignore
          resultArray.add(userObject.personal[key]);
        } else {
          // @ts-ignore
          resultArray.add(userObject[key]);
        }
      });

      return Array.from(resultArray) as string[];
    },

    filterCompanies: (sourceArray: [], filterOptions: any) => {
      const filterByKey = (source: any[], filterKey: string) => {
        let result: any[] = [];

        source.forEach((companyObject: any) => {
          if (typeof filterOptions[filterKey] === 'string') {
            if (companyObject[filterKey] === filterOptions[filterKey]) {
              result.push(companyObject);
            }
          } else {
            filterOptions[filterKey].forEach((element: string) => {
              if (element === companyObject[filterKey]) {
                result.push(companyObject);
              }
            });
          }
        });

        return result;
      };

      // const filteredByCountryCompanies: any[] = filterByKey(sourceArray, 'country_name');
      // const filteredByReasonCompanies: any[] = filterByKey(filteredByCountryCompanies, 'reason');
      // return filteredByReasonCompanies;

      let filteredCompanies: any[] = [];
      let isCountryValue = false;
      let isReasonValue = false;

      Object.keys(filterOptions).forEach((filterKey: any) => {
        if (Array.isArray(filterOptions[filterKey]) && filterOptions[filterKey].length) {
          isCountryValue = true;
        }

        if (typeof filterOptions[filterKey] === 'string' && filterOptions[filterKey] !== '') {
          isReasonValue = true;
        }
      });

      if (isCountryValue && isReasonValue) {
        const filteredByCountryCompanies: any[] = filterByKey(sourceArray, 'country_name');
        const filteredByReasonCompanies: any[] = filterByKey(filteredByCountryCompanies, 'reason');
        filteredCompanies = filteredByReasonCompanies;
      } else {
        Object.keys(filterOptions).forEach((filterKey: any) => {
          if (typeof filterOptions[filterKey] === 'string' && filterOptions[filterKey] !== '') {
            filteredCompanies.push(...filterByKey(sourceArray, filterKey));
          } else if (Array.isArray(filterOptions[filterKey]) && filterOptions[filterKey].length) {
            filteredCompanies.push(...filterByKey(sourceArray, filterKey));
          }
        });
      }

      return filteredCompanies;
    },

    searchCompanies: (sourceArray: any[], optionsObject: {}) => {
      const matchesSet = new Set();

      sourceArray.forEach((item: any) => {
        Object.keys(item).forEach((key: string) => {
          if (typeof item[key] === 'object') {
            Object.keys(item[key]).forEach((k) => {
              // @ts-ignore
              if (optionsObject[k] === '') return;
              // @ts-ignore
              if (String(item[key][k]).toLowerCase().includes(String(optionsObject[k]).toLowerCase())) {
                matchesSet.add(item);
              }
            });
          } else {
            // @ts-ignore
            if (optionsObject[key] === '') return;
            // @ts-ignore
            if (String(item[key]).toLowerCase().includes(String(optionsObject[key]).toLowerCase())) {
              matchesSet.add(item);
            }
          }
        });
      });

      return Array.from(matchesSet);
    },

    searchCountries: (sourceArray: any, option: string) => {
      let result: any[] = [];

      sourceArray.forEach((item: any) => {
        // if (item.country.includes(option)) {
        //     result.push(item);
        // }
        if (item.country.toLowerCase().startsWith(option.toLowerCase())) {
          result.push(item);
        }
      });

      return result;
    },

    searchCities: (sourceArray: any, option: string) => {
      let result: any[] = [];

      sourceArray.forEach((item: any) => {
        // if (item.name.includes(option)) {
        //     result.push(item);
        // }
        if (item.name.toLowerCase().startsWith(option.toLowerCase())) {
          result.push(item);
        }
      });

      return result;
    },

    isFormDataEmpty: (targetObject: any) => {
      let values: boolean[] = [];
      let isEmpty: boolean = true;

      Object.keys(targetObject).forEach((key: string) => {
        if (Array.isArray(targetObject[key])) {
          values.push(!targetObject[key].length);
          return;
        }
        if (typeof targetObject[key] === 'string') {
          values.push(targetObject[key] === '');
          return;
        }
      });

      values.forEach((value: boolean) => {
        if (!value) {
          isEmpty = false;
          return;
        }
      });

      return isEmpty;
    },

    clearFormData: (targetObject: any) => {
      let resultObject: any = targetObject;

      Object.keys(targetObject).forEach((key: string) => {
        if (Array.isArray(targetObject[key])) {
          // resultObject[key] = targetObject[key];
          resultObject[key] = [];
        } else if (typeof targetObject[key] === 'object' && !Array.isArray(targetObject[key])) {
          BlacklistUtils.clearFormData(targetObject[key]);
        } else {
          // resultObject[key] = targetObject[key];
          resultObject[key] = '';
        }
      });

      return resultObject;
    },
  };

  return {
    renderQuestions,
    renderFilteredQuestions,
    getDefaultActiveCategoryItems,
    generateQuestionLink,
    getQuestionIdFromUrl,
    setRequestUrlHostname,
    defineAppDataType,
    getCorrectPathname,
    convertDate,
    capitalizeFirstChar,
    parseCountries,
    BlacklistUtils,
  };
};
