import React, { FC, ReactElement, memo } from 'react';
import TopArticlesCard from './TopArticlesCard';
import Link from 'next/link';

interface ITopArticles {
  articles: ITopArticlesCard[];
}

export interface ITopArticlesCard {
  id: number;
  title: string;
  tag: string;
  date: string;
  url: string;
  image: string;
}

const TopArticles: FC<ITopArticles> = memo(({ articles: newsData }: ITopArticles): ReactElement => {
  const cardElements = newsData?.map((card: ITopArticlesCard, index: number) => {
    return (
      <TopArticlesCard
        key={card.id}
        index={++index}
        title={card.title}
        tag={card.tag}
        date={card.date}
        url={card.url}
      />
    );
  });

  return (
    <div className="latest-news">
      <h2 className="latest-news__heading">Top articles</h2>
      <div className="latest-news__link-box">
        <Link className="new-link arrow" href="/blog">
          View all articles
          <svg className="link-arrow directionRight" viewBox="0 0 10 10" aria-hidden="true">
            <path className="link-arrow__line" d="M1 5h7" />
            <path className="link-arrow__tip" d="M2 1l4 4-4 4" />
          </svg>
        </Link>
      </div>
      <ul className="latest-news__card-list">{cardElements}</ul>
    </div>
  );
});

export default TopArticles;
